.input {
  min-width: var(--lp-size-32);
}

.tagGroup, .tagList, .container {
  display: contents;
}

.group {
  gap: var(--lp-spacing-300);
  flex-wrap: wrap;
  flex: 1;
  display: flex;
}
