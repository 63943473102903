.page {
  flex-direction: column;
  width: 100%;
  min-height: calc(100vh - 4.75rem);
  display: flex;

  & > * {
    flex: 1 0;
    width: 100%;
  }
}

.page > div:first-child, .page > main:first-child, .page > section:first-child {
  z-index: calc(var(--stacking-above-new-context)  + 1);
  flex-direction: column;
  display: flex;

  & :global(.Module-header) {
    text-align: center;
    width: 25rem;
    margin: 15vh auto 0;
  }

  & :global(.Module-content) {
    gap: var(--lp-size-18);
    border-radius: .5rem;
    flex-direction: column;
    height: fit-content;
    margin: 1.5rem auto;
    display: flex;
  }

  & :global(.Module) {
    flex-direction: column;
    align-items: center;
    display: flex;
  }
}

@media screen and (width >= 62rem) {
  .page {
    flex-direction: row;

    & > * {
      flex: 1 0 50%;
      width: 50%;
    }
  }
}

.dark {
  background: var(--lp-color-gray-900);
}

.dark > div:first-child, .dark > main:first-child, .dark > section:first-child {
  background: var(--lp-color-gray-900);
  opacity: .97;
}

@media screen and (width <= 480px) {
  :global(body) {
    width: 125%;
  }
}
