.modalContent {
  overflow: scroll;
}

.roleControls {
  display: flex;
  justify-content: space-between;
}

.button {
  display: flex;
  align-items: flex-start;
  max-width: var(--lp-size-400);
}

.diff {
  overflow-y: scroll;
  max-height: var(--lp-size-640);
  margin-bottom: var(--lp-spacing-400);
}
