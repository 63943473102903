.fallback {
  display: flex;
  flex-direction: row;
  gap: var(--lp-size-4);
  flex: 1;
}

.fallback [slot='remove'] {
  display: none;
}

.visual {
  width: var(--lp-size-16);
  height: var(--lp-size-16);
  border-radius: 100%;
  background-color: var(--lp-color-bg-ui-tertiary);
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

.text {
  height: var(--lp-size-16);
  width: var(--lp-size-64);
  background-color: var(--lp-color-bg-ui-tertiary);
  border-radius: var(--lp-border-radius-regular);
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

@keyframes pulse {
  50% {
    opacity: 0.5;
  }
}
