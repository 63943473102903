.trialBanner {
  background: var(--lp-color-brand-blue-base);
  width: 100%;
  padding: var(--lp-spacing-300) var(--lp-spacing-500);
  justify-content: center;
  align-items: center;
  gap: var(--lp-spacing-900);
  white-space: nowrap;
  display: flex;

  &.expired {
    background: var(--lp-color-red-500);
  }

  &.footer {
    position: absolute;
    bottom: 0;
  }
}

.bannerText {
  color: var(--lp-color-white-950);
  align-items: center;
  gap: var(--lp-spacing-200);
  display: flex;

  & h2 {
    font: var(--lp-text-body-1-semibold);
    color: var(--lp-color-white-950);
  }

  & p {
    font: var(--lp-text-body-1-regular);
  }
}
