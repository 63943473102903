.clamp {
  --line-count: 0;
}

.clamp.active {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: var(--line-count);
  -webkit-box-orient: vertical;
  word-break: break-word;
}
