.LoginForm-password:not(.is-required) {
  height: 0;
  margin: 0;
  position: absolute;
  overflow: hidden;
}

[class*="formIncreasedErrorMargin"].has-a11y-updates .LoginForm-password:not(.is-required) + fieldset {
  margin-top: 0;
}

.LoginForm-password:not(.is-required) .hint a {
  display: none;
}

.LoginForm--federal {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 41.6875rem;
  display: flex;
}

.Login--federalWarning {
  margin-top: .625rem;
}

.LoginModule--federal {
  width: 100%;
}

button.LoginForm-submit {
  border-radius: 0;

  &:focus, &:focus-visible {
    border-radius: 0;
  }
}

.hint a {
  color: var(--lp-color-gray-200);
}
