.Module-header {
  padding-bottom: .3125rem;
}

.Module-content {
  color: var(--lp-color-text-ui-secondary);
  margin-bottom: 1.25rem;
  position: relative;
}

.Module-content:not(.Module-content--snug) {
  padding: var(--lp-size-20);
}

.Module-content.Module-content--draft {
  border-radius: var(--lp-border-radius-regular);
  color: var(--lp-color-text-ui-secondary);
  border-style: dashed;
  border-width: 1px;
  border-color: var(--lp-color-border-ui-primary);
  text-align: center;
  font-weight: var(--lp-font-weight-medium);
  background: none;
}

.Module-content:not(.Module-content--seamless):not(.Module-content--draft) {
  background-color: var(--color-bg-card);
  border-radius: var(--lp-border-radius-regular);
  border: 1px solid var(--lp-color-border-ui-primary);
}

.Module-empty {
  border-radius: var(--lp-border-radius-regular);
  color: var(--lp-color-text-ui-secondary);
  border-style: dashed;
  border-width: 1px;
  border-color: var(--lp-color-border-ui-primary);
  text-align: center;
  font-weight: var(--lp-font-weight-medium);
  background: none;
  padding: .625rem 1.25rem;
}

button.Module-empty {
  width: 100%;
}

.Module-empty--interactive:hover {
  cursor: pointer;
  background: #fff;
  border-style: solid;
}

.Module-content .Module-actions {
  position: absolute;
  top: -8px;
  right: -8px;
}

.Module-content .Module-actions > [class*="_Button"], .Module-content .Module-actions > .Drop-target {
  float: right;
  margin-left: 10px;
  line-height: 1;
}

.Module-content--snug .DataListToolbar:first-child, .Module-content--snug .DataList:first-child .DataList-item:first-child {
  border-top-left-radius: var(--lp-border-radius-regular);
  border-top-right-radius: var(--lp-border-radius-regular);
}

.Module-content--snug .DataListToolbar:last-child, .Module-content--snug .DataList:last-child .DataList-item:last-child {
  border-bottom-left-radius: var(--lp-border-radius-regular);
  border-bottom-right-radius: var(--lp-border-radius-regular);
}

.Module-content--condensedY {
  margin-bottom: 0;
}

.Module-content--condensedY:not(:first-of-type) {
  padding-top: 0;
}

.Module-content--addSpaceBelow {
  padding-bottom: 1.25rem !important;
}

.Module-loading {
  justify-content: center;
  align-items: center;
  padding: 1.875rem;
  display: flex;
}

.Module-content:focus {
  outline: 2px solid var(--lp-color-shadow-interactive-focus);
}

.Module-content:focus:not(:focus-visible) {
  outline: none;
}
