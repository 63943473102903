:root {
  --Form-flow-duration: .2s;
}

.Form-group {
  border: none;
  margin: 1.25rem 0;
  padding: 0;
}

.Form--increasedErrorMargin.has-a11y-updates .Form-group {
  margin: 1.75rem 0;
}

.Form .Form-group:first-child {
  margin-top: 0;
}

.Form .Form-group:last-child {
  margin-bottom: 0;
}

.Form--inline .Form-group {
  vertical-align: middle;
  margin: 0;
  display: inline-block;
}

.Form--inline .Form-group + .Form-group, .Form--inline .Form-group + [class*="_Button"] {
  margin-left: .625rem;
}

.Form-group label {
  align-items: center;
  margin-bottom: .125rem;
  display: flex;
}

.Form-group label + label {
  margin-top: .3125rem;
}

.Form-group.Default-TTL {
  font-size: var(--lp-font-size-300);
  color: var(--lp-color-text-ui-primary-base);
  font-weight: var(--lp-font-weight-medium);
}

.Form .Form-error {
  background: var(--lp-color-text-feedback-error);
  color: #fff;
  font-weight: var(--lp-font-weight-regular);
  border-radius: var(--lp-border-radius-regular);
  padding: .4375rem .875rem;
}

.Form-fieldError {
  color: var(--lp-color-text-feedback-error);
  font-size: var(--lp-font-size-200);
}

.Form--increasedErrorMargin.has-a11y-updates .Form-fieldError {
  min-height: 1.75rem;
}

.Form:not(.Form--inline) .Form-fieldError {
  text-align: left;
  padding-top: .3125rem;
  display: block;
}

.Form--increasedErrorMargin:not(.Form--inline).has-a11y-updates .Form-fieldError {
  padding-top: .0625rem;
  padding-bottom: .3125rem;
}

::placeholder {
  color: var(--lp-color-text-field-placeholder);
}

.Form .is-invalid .Select-control, .Form .is-invalid .CustomSelect > div, .Form .is-invalid input {
  border-color: var(--lp-color-text-feedback-error);
}

.Form-flow {
  display: flex;
  position: relative;
}

.Form-flow > * {
  flex: 1 0 100%;
  width: 100%;
}

.form-enter {
  opacity: 0;
  transition: transform var(--Form-flow-duration), opacity var(--Form-flow-duration);
  transform: translateX(0%);
}

.form-enter.form-enter-active {
  opacity: 1;
  transform: translateX(-100%);
}

.form-leave {
  opacity: 1;
  transition: transform var(--Form-flow-duration), opacity var(--Form-flow-duration);
  transform: translateX(0%);
}

.form-leave.form-leave-active {
  opacity: 0;
  transform: translateX(-100%);
}

.Form--increasedErrorMargin.has-a11y-updates .Form-group.is-invalid {
  margin-bottom: 0;
}

.Form--increasedErrorMargin.has-a11y-updates .Form-group.is-invalid + .Form-group, .Form--increasedErrorMargin.has-a11y-updates .Form-group.is-invalid + .Form-hint {
  margin-top: 0;
}

.FormInput {
  width: 100%;
  font-size: .8125rem;
  font-family: var(--lp-font-family-base);
  line-height: var(--lp-line-height-300);
  background-color: var(--lp-color-bg-field-base);
  border: 1px solid var(--lp-color-border-field-base);
  border-radius: var(--lp-border-radius-regular);
  color: var(--lp-color-text-ui-primary-base);
  padding: .375rem .625rem;
  transition: all .1s linear;
  display: block;
}

.FormInput:placeholder-shown {
  text-overflow: ellipsis;
  overflow: hidden;
}

.FormInput.isDisabled, input.FormInput:disabled, select.FormInput:disabled, input.FormInput:read-only {
  opacity: 1;
  border-color: var(--lp-color-border-field-disabled);
  background-color: var(--lp-color-bg-field-disabled);
  color: var(--lp-color-text-field-disabled);
}

.FormInput.isDisabled:hover, .FormInput:disabled:hover {
  cursor: not-allowed;
}

textarea.FormInput {
  resize: none;
  height: auto;
  min-height: 2rem;
  line-height: 1.125rem;
}

textarea.FormInput:disabled, textarea.FormInput:read-only {
  opacity: 1;
  color: var(--lp-color-text-field-disabled);
  background-color: var(--lp-color-bg-field-disabled);
  border-color: var(--lp-color-border-field-disabled);
}

input.FormInput::-webkit-autofill {
  box-shadow: 0 0 0 50px var(--lp-color-bg-interactive-secondary-base) inset;
}

.FormInput.isFocused, .FormInput:focus {
  border-color: var(--lp-color-border-field-focus);
  outline: 0;
  box-shadow: 0 0 0 3px #405bff1a;
}

.FormInput[readonly], .FormInput[readonly]:focus {
  color: var(--lp-color-text-ui-secondary);
  border-color: var(--lp-color-gray-500);
  box-shadow: none;
}

select.FormInput {
  appearance: none;
  background-color: #0000;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\"><path d=\"M7.41 8.84L12 13.42l4.59-4.58L18 10.25l-6 6-6-6 1.41-1.41z\" fill=\"%23646f7a\"/></svg>");
  background-position: right .4em top 50%, 0 0;
  background-repeat: no-repeat;
  background-size: 1.25rem;
  background-attachment: scroll;
  background-origin: padding-box;
  background-clip: border-box;
  padding-right: 1.25rem;
}

:root[data-type-refresh] {
  & .Form-group.Default-TTL {
    font: var(--lp-text-body-1-regular);
  }

  & .Form-fieldError {
    font: var(--lp-text-body-2-regular);
  }
}
