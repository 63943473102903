.icon {
  display: inline-block;
  fill: currentcolor;
  flex: none;
  position: relative;
  vertical-align: middle;
}

.subtle {
  fill: var(--lp-color-gray-600);
}

.micro {
  height: 0.4375rem;
  width: 0.4375rem;
}

.tiny {
  height: 0.625rem;
  width: 0.625rem;
}

.small {
  height: 1rem;
  width: 1rem;
}

.medium {
  height: 1.25rem;
  width: 1.25rem;
}

.mlarge {
  height: 1.875rem;
  width: 1.875rem;
}

.large {
  height: 2.5rem;
  width: 2.5rem;
}

.xlarge {
  height: 3.75rem;
  width: 3.75rem;
}

.huge {
  height: 6.25rem;
  width: 6.25rem;
}

.icon svg {
  display: block;
  height: 100%;
  overflow: visible;
  width: 100%;
}
