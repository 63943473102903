/* matching the styles from LP Filter component */
:root,
[data-theme='default'] {
  --bg-clearable: rgb(0 0 0 / 0.15);
  --bg-clearable-focus: rgb(0 0 0 / 0.2);
  --border-clearable-focus: var(--lp-color-gray-800);
}
[data-theme='dark'] {
  --bg-clearable: rgb(248 248 248 / 0.15);
  --bg-clearable-focus: rgb(248 248 248 / 0.2);
  --border-clearable-focus: var(--lp-color-gray-500);
}
.button,
.clearButton {
  border-color: transparent;
}
.clearButton {
  margin-right: var(--lp-spacing-200);
}
.buttonGroup {
  gap: 0;
  border-radius: var(--lp-border-radius-medium);
  display: flex;
  align-items: center;
  cursor: pointer;
  border: 1px solid transparent;
  background: var(--lp-color-bg-interactive-secondary-base);

  &:hover {
    border: 1px solid var(--lp-color-border-interactive-secondary-base);
    background: var(--lp-color-bg-interactive-secondary-hover);

    .button,
    .clearButton {
      background: transparent;
    }
  }
}
.buttonGroup.active {
  background: var(--bg-clearable);

  .button,
  .clearButton {
    background: transparent;
  }

  .button {
    padding-right: 0;
  }

  &:hover {
    background: var(--bg-clearable-focus);
    border: 1px solid var(--border-clearable-focus);
  }

  .clearButton:hover {
    background: var(--lp-color-bg-interactive-secondary-hover);
  }
}
