.icon {
  &.first {
    margin-right: .15625rem;
  }

  &.last {
    margin-left: .15625rem;
  }
}

:global(.u-fs-sm) .icon {
  width: .875rem;
  height: .875rem;
  margin-left: .0625rem;
}
