.UnauthenticatedPage {
  height: 100vh;
}

@media screen and (width >= 62rem) {
  .UnauthenticatedPage {
    padding: 0;
  }
}

.content {
  min-height: calc(100vh - 4.75rem);

  & h1 {
    font-size: var(--lp-font-size-500);
    margin-bottom: .625rem;
  }
}

:global(.UnauthenticatedPage-otherActions) {
  border-top: 1px solid var(--lp-color-border-ui-secondary);
  margin-top: 1.25rem;
  padding-top: .625rem;
}

:global(.Divider):after {
  background-color: inherit;
  color: inherit;
}

:root[data-type-refresh] {
  & .content {
    & h1 {
      font: var(--lp-text-heading-1-medium);
    }
  }
}
