.logoWithWordmarkHeader {
  align-items: center;
  margin: .9375rem 0;
  display: flex;
}

@media screen and (width >= 46.25rem) {
  .logoWithWordmarkHeader {
    margin: .9375rem 1.25rem;
  }
}

@media screen and (width >= 0) {
  .logoWithWordmarkHeader {
    padding: .625rem;
  }
}

.logoWithWordmarkHeader svg {
  fill: var(--lp-color-gray-950);
  width: 200px;
  height: 50px;
}

.logoWithWordmarkHeader a {
  display: inline-block;
}

.logoHeader {
  margin-left: .75rem;
}

.dark {
  background: var(--lp-color-gray-950);
  margin: 0;
  padding: .625rem 1.25rem;

  & svg {
    fill: var(--lp-color-white-950);
  }
}

.dark.noBackground {
  background: var(--lp-color-gray-900);
}
