.diamond {
  --default-fill-color: var(--lp-color-bg-ui-primary);

  vertical-align: middle;
  display: inline-grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
}

.outline {
  grid-area: 1/1;
  fill: var(--fill-color, var(--default-fill-color));
  stroke: var(--stroke-color, transparent);
  stroke-width: var(--stroke-width, 0);
}

.fill {
  grid-area: 1/1;
  fill: var(--fill-color, var(--default-fill-color));
}
