.icon {
  fill: currentColor;
  vertical-align: middle;
  flex: none;
  display: inline-block;
  position: relative;
}

.subtle {
  fill: var(--lp-color-gray-600);
}

.micro {
  width: .4375rem;
  height: .4375rem;
}

.tiny {
  width: .625rem;
  height: .625rem;
}

.small {
  width: 1rem;
  height: 1rem;
}

.medium {
  width: 1.25rem;
  height: 1.25rem;
}

.mlarge {
  width: 1.875rem;
  height: 1.875rem;
}

.large {
  width: 2.5rem;
  height: 2.5rem;
}

.xlarge {
  width: 3.75rem;
  height: 3.75rem;
}

.huge {
  width: 6.25rem;
  height: 6.25rem;
}

.icon svg {
  width: 100%;
  height: 100%;
  display: block;
  overflow: visible;
}
