.featureListItem {
  list-style-type: none;
  display: flex;
  padding: var(--lp-spacing-200) var(--lp-spacing-100);
  align-items: center;
  gap: var(--lp-spacing-200);
  align-self: stretch;

  .icon {
    padding-right: var(--lp-spacing-200);
  }
}

.popover {
  padding: var(--lp-spacing-200) var(--lp-spacing-300);
  font: var(--lp-text-body-2-regular);
}
