.separatorContainer {
  display: flex;
  flex-direction: row;

  hr {
    position: relative;
    border: none;
    width: var(--lp-size-176);
    border-top: 1px solid var(--lp-color-border-ui-primary);
    top: var(--lp-size-6);
  }

  h3 {
    padding: 0 var(--lp-size-14);
  }
}
