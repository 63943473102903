.container {
  border: 1px solid var(--lp-color-border-ui-secondary);

  /* GitHub's black */
  background: #24292f;

  a {
    color: var(--lp-color-text-ui-primary-base);
    text-decoration: none;
    font-weight: var(--lp-font-weight-medium);
    font-size: var(--lp-font-size-200);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    position: relative;

    .icon {
      position: absolute;
      left: var(--lp-spacing-300);
    }
  }

  a:active:not([class*='_Button']):not([data-pressed]):not([data-focused]) {
    color: var(--lp-color-text-ui-primary-base);
    text-decoration: none;
    outline: 0;
  }
}
