.planCardWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--lp-spacing-400);
  min-width: 280px;
  max-width: 320px;
  border-radius: var(--lp-border-radius-medium);
  padding: var(--lp-spacing-600);
  background: var(--lp-color-bg-ui-primary);
  border: 1px solid var(--lp-color-border-ui-primary);
  min-height: 512px;
  box-shadow:
    0px 0px 1px 0px var(--lp-color-shadow-ui-secondary),
    0px 0px 4px 0px var(--lp-color-shadow-ui-secondary),
    0px 4px 8px 0px var(--lp-color-shadow-ui-secondary),
    0px 2px 12px 0px var(--lp-color-shadow-ui-secondary);
  color: var(--lp-color-text-ui-primary-base);

  &.recommended {
    border: 2px solid transparent;
    background:
      linear-gradient(var(--lp-color-bg-ui-primary), var(--lp-color-bg-ui-primary)) padding-box,
      linear-gradient(1.95deg, #7084ff -12.49%, #405bff 112.04%) border-box;

    .recommendedButtonGradient {
      background: linear-gradient(360deg, #3dd6f5 -135%, #405bff 100%);
    }
    .recommendedButtonGradient[data-hovered] {
      filter: brightness(1.2);
      cursor: pointer;
    }
  }
}
.planCardTitle {
  font-family: var(--lp-font-family-base);
  font-size: var(--lp-font-size-400);
  font-weight: var(--lp-font-weight-semibold);
  line-height: var(--lp-line-height-300);
}
.planCardDescription {
  font: var(--lp-text-body-2-regular);
  color: var(--lp-color-text-ui-primary-base);
}
.planCardPrice {
  font-family: var(--lp-font-family-base);
  font-size: var(--lp-font-size-400);
  font-weight: var(--lp-font-weight-semibold);
  line-height: var(--lp-line-height-400);
}
.topSection {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--lp-spacing-500);
  padding-bottom: var(--lp-spacing-600);
  border-bottom: 1px solid var(--lp-color-border-ui-primary);
}
.planCardPriceDescription {
  color: var(--lp-color-text-ui-secondary);
  font: var(--lp-text-body-2-regular);
}
.bottomSection {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: var(--lp-spacing-200);
  gap: 4px;
}
.bottomSectionTitle {
  font: var(--lp-text-body-2-semibold);
  color: var(--lp-color-text-ui-primary-base);
}
.bottomSectionFeatures {
  font: var(--lp-text-body-2-regular);
  color: var(--lp-color-text-ui-primary-base);
}
