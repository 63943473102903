.Form-hint {
  display: block;
  margin-top: 0.1875rem;
  font-size: var(--lp-font-size-200);
  font-weight: var(--lp-font-weight-regular);
  color: var(--lp-color-text-ui-secondary);
}

.Form-hint a {
  color: inherit;
}

:root[data-type-refresh] {
  .Form-hint {
    font: var(--lp-text-body-2-regular);
  }
}
