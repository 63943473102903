.table, .resourcesCell {
  width: 100%;
}

.buttonGroup {
  margin-top: var(--lp-spacing-500);
  justify-content: flex-end;
  display: flex;
}

.tableHeader {
  border-bottom: 1px solid var(--lp-color-border-ui-primary);
  align-items: start;
  column-gap: var(--lp-spacing-500);
  grid-template-columns: 150px 1fr;
  display: grid;
}

.headerCell {
  padding: var(--lp-spacing-300);
  font-weight: var(--lp-font-weight-semibold);
}

.tableBody {
  flex-direction: column;
  display: flex;
}

.tableRow {
  border-bottom: 1px solid var(--lp-color-border-ui-primary);
  align-items: start;
  column-gap: var(--lp-spacing-500);
  grid-template-columns: 150px 1fr;
  display: grid;
}

.tableRow:last-child {
  border-bottom: none;
}

.cell {
  padding: var(--lp-spacing-300);
}

.attributeName {
  font-size: var(--lp-font-size-200);
}

.headerCell:first-child, .cell:first-child {
  word-wrap: break-word;
  overflow-wrap: break-word;
  height: 100%;
  padding: var(--lp-spacing-300);
}

.formDescription {
  margin-top: var(--lp-spacing-500);
  font-size: var(--lp-font-size-200);
}

.roleKindSelect {
  margin-bottom: var(--lp-spacing-300);
}
