.separatorContainer {
  flex-direction: row;
  display: flex;

  & hr {
    width: var(--lp-size-176);
    border: none;
    border-top: 1px solid var(--lp-color-border-ui-primary);
    top: var(--lp-size-6);
    position: relative;
  }

  & h3 {
    padding: 0 var(--lp-size-14);
  }
}
