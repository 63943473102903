:global(.RulesOfBehavior) [aria-modal] {
  max-width: 50%;
  max-height: 50%;
  overflow: scroll;
}

.Login {
  height: 100%;
  display: flex;

  & > div {
    width: 100%;
  }
}

.content {
  z-index: calc(var(--stacking-above-new-context)  + 1);

  & > div {
    width: 25rem;
    margin: auto;
  }
}

.button {
  width: 100%;
}

.federal {
  flex-direction: column;
  align-items: center;
  width: 41.6875rem;
  display: flex;
}

.federalWarning {
  margin-top: .625rem;
  margin-bottom: 1.25rem;
}

.LoginModuleFederal {
  width: 100%;
}

.otherActions {
  margin-top: 1.25rem;
  padding-top: .625rem;
}

.otherActions > p:first-child {
  margin-bottom: .5rem;
}

h1, h2, h3 {
  color: var(--lp-color-white-950);
}

p, label {
  color: var(--lp-color-gray-200);
}
