.trialBanner {
  background: var(--lp-color-brand-blue-base);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: var(--lp-spacing-300) var(--lp-spacing-500);
  gap: var(--lp-spacing-900);
  white-space: nowrap;

  &.expired {
    background: var(--lp-color-red-500);
  }

  &.footer {
    bottom: 0;
    position: absolute;
  }
}

.bannerText {
  display: flex;
  align-items: center;
  color: var(--lp-color-white-950);
  gap: var(--lp-spacing-200);

  h2 {
    font: var(--lp-text-body-1-semibold);
    color: var(--lp-color-white-950); /* common.css sets the default color, so we're resetting the color here */
  }

  p {
    font: var(--lp-text-body-1-regular);
  }
}
