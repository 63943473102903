@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 0.5;
  }
  50% {
    transform: scale(1.2);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0.5;
  }
}

.heart {
  fill: var(--lp-color-bg-ui-primary);

  &.isAnimated {
    transform-box: fill-box;
    transform-origin: center;
    animation: pulse 3s ease-in-out infinite;
  }
}
