.diffTypeControl {
  display: flex;
  flex-direction: row;
  gap: var(--lp-spacing-200);
}

.controls {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  gap: var(--lp-spacing-200);
  width: 100%;
  margin-bottom: var(--lp-spacing-200);
}

.controlLabel {
  font: var(--lp-text-label-1-regular);
}

.diff {
  white-space: pre-wrap;
  background-color: var(--lp-color-bg-ui-secondary);
  padding: var(--lp-spacing-400);
  font: var(--lp-text-code-1-regular);
  padding-left: 0;
  border-radius: var(--lp-border-radius-medium);
}

.title {
  font: var(--lp-text-label-1-semibold);
}

.diffView {
  margin-top: var(--lp-spacing-400);
  position: relative;
}

.removed,
.added {
  &::before {
    content: '+';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: var(--lp-size-48);
    font: var(--lp-text-code-1-regular);
    color: var(--lp-color-text-ui-secondary);
  }
}

.added {
  background-color: var(--lp-color-green-50);
  position: relative;

  &::before {
    content: '+';
  }

  [data-theme='dark'] & {
    background-color: var(--lp-color-green-800);
  }

  .lineNumber {
    left: var(--lp-spacing-800);
  }
}

.removed {
  background-color: var(--lp-color-red-50);
  position: relative;

  &::before {
    content: '-';
  }

  [data-theme='dark'] & {
    background-color: var(--lp-color-red-800);
  }
}

.unchanged {
  color: var(--lp-color-text-ui-primary-base);
}

.unchanged,
.removed,
.added {
  padding-left: var(--lp-size-64);
  position: relative;
}

.lineNumber {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: var(--lp-spacing-300);
  font: var(--lp-text-code-1-regular);
  color: var(--lp-color-text-ui-secondary);
}

.lineNumberUnchanged {
  left: var(--lp-spacing-800);
}

.addedInner {
  background-color: var(--lp-color-green-200);

  [data-theme='dark'] & {
    background-color: var(--lp-color-green-600);
  }
}

.removedInner {
  background-color: var(--lp-color-red-200);

  [data-theme='dark'] & {
    background-color: var(--lp-color-red-600);
  }
}

.diffViewSameDoc {
  .lineNumberUnchanged {
    display: none;
  }
}
