.container {
  border: 1px solid var(--lp-color-border-ui-secondary);
  background: #24292f;

  & a {
    color: var(--lp-color-text-ui-primary-base);
    font-weight: var(--lp-font-weight-medium);
    font-size: var(--lp-font-size-200);
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    text-decoration: none;
    display: flex;
    position: relative;

    & .icon {
      left: var(--lp-spacing-300);
      position: absolute;
    }
  }

  & a:active:not([class*="_Button"]):not([data-pressed]):not([data-focused]) {
    color: var(--lp-color-text-ui-primary-base);
    outline: 0;
    text-decoration: none;
  }
}
