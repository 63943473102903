.fallback {
  gap: var(--lp-size-4);
  flex-direction: row;
  flex: 1;
  display: flex;
}

.fallback [slot="remove"] {
  display: none;
}

.visual {
  width: var(--lp-size-16);
  height: var(--lp-size-16);
  background-color: var(--lp-color-bg-ui-tertiary);
  border-radius: 100%;
  animation: 2s cubic-bezier(.4, 0, .6, 1) infinite pulse;
}

.text {
  height: var(--lp-size-16);
  width: var(--lp-size-64);
  background-color: var(--lp-color-bg-ui-tertiary);
  border-radius: var(--lp-border-radius-regular);
  animation: 2s cubic-bezier(.4, 0, .6, 1) infinite pulse;
}

@keyframes pulse {
  50% {
    opacity: .5;
  }
}
