.modalContent {
  overflow: scroll;
}

.roleControls {
  justify-content: space-between;
  display: flex;
}

.button {
  max-width: var(--lp-size-400);
  align-items: flex-start;
  display: flex;
}

.diff {
  max-height: var(--lp-size-640);
  margin-bottom: var(--lp-spacing-400);
  overflow-y: scroll;
}
