:root, [data-theme="default"] {
  --bg-clearable: #00000026;
  --bg-clearable-focus: #0003;
  --border-clearable-focus: var(--lp-color-gray-800);
}

[data-theme="dark"] {
  --bg-clearable: #f8f8f826;
  --bg-clearable-focus: #f8f8f833;
  --border-clearable-focus: var(--lp-color-gray-500);
}

.button, .clearButton {
  border-color: #0000;
}

.clearButton {
  margin-right: var(--lp-spacing-200);
}

.buttonGroup {
  border-radius: var(--lp-border-radius-medium);
  cursor: pointer;
  background: var(--lp-color-bg-interactive-secondary-base);
  border: 1px solid #0000;
  align-items: center;
  gap: 0;
  display: flex;

  &:hover {
    border: 1px solid var(--lp-color-border-interactive-secondary-base);
    background: var(--lp-color-bg-interactive-secondary-hover);

    & .button, & .clearButton {
      background: none;
    }
  }
}

.buttonGroup.active {
  background: var(--bg-clearable);

  & .button, & .clearButton {
    background: none;
  }

  & .button {
    padding-right: 0;
  }

  &:hover {
    background: var(--bg-clearable-focus);
    border: 1px solid var(--border-clearable-focus);
  }

  & .clearButton:hover {
    background: var(--lp-color-bg-interactive-secondary-hover);
  }
}
